import { combineReducers } from "redux"
import { penderReducer } from "redux-pender"

import ConfigReducer from "./config"
import AppReducer from "./app"

export default combineReducers({
  pender: penderReducer,
  ConfigReducer,
  AppReducer,
})
