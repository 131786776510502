import { createAction } from "redux-actions"
import appApis from "../apis/appApis"

// action type
export const SET_USER = "@trams-logistics-app/app/SET_USER"
export const SET_USER_TYPE = "@trams-logistics-app/app/SET_USER_TYPE"
export const SET_SCAN_LARGE_POUCH_TRACKS = "@trams-logistics-app/app/SET_SCAN_LARGE_POUCH_TRACKS"
export const SET_SCAN_POUCH_TRACKS = "@trams-logistics-app/app/SET_SCAN_POUCH_TRACKS"
export const SET_SCAN_FREIGHT_TRACKS = "@trams-logistics-app/app/SET_SCAN_FREIGHT_TRACKS"
export const SET_DRIVER_POINTS = "@trams-logistics-app/app/SET_DRIVER_POINTS"
export const SET_FREIGHT_TRACKS = "@trams-logistics-app/app/SET_FREIGHT_TRACKS"
export const SET_FREIGHT_TRACKS_TOTAL = "@trams-logistics-app/app/SET_FREIGHT_TRACKS_TOTAL"
export const SET_POUCH_TRACKS = "@trams-logistics-app/app/SET_POUCH_TRACKS"
export const SET_POUCH_TRACKS_TOTAL = "@trams-logistics-app/app/SET_POUCH_TRACKS_TOTAL"
export const SET_PICKUP_POUCHS = "@trams-logistics-app/app/SET_PICKUP_POUCHS"

export const SET_CUST_LIST = "@trams-logistics-app/app/SET_CUST_LIST"

// action function
export const setUser = createAction(SET_USER)
export const setUserType = createAction(SET_USER_TYPE)
export const setScanLargePouchTracks = createAction(SET_SCAN_LARGE_POUCH_TRACKS)
export const setScanPouchTracks = createAction(SET_SCAN_POUCH_TRACKS)
export const setScanFreightTracks = createAction(SET_SCAN_FREIGHT_TRACKS)
export const setDriverPoints = createAction(SET_DRIVER_POINTS)
export const setFreightTracks = createAction(SET_FREIGHT_TRACKS)
export const setFreightTracksTotal = createAction(SET_FREIGHT_TRACKS_TOTAL)
export const setPouchTracks = createAction(SET_POUCH_TRACKS)
export const setPouchTracksTotal = createAction(SET_POUCH_TRACKS_TOTAL)
export const setPickupPouchs = createAction(SET_PICKUP_POUCHS)

export const setCustList = createAction(SET_CUST_LIST)

// action api function

export const getCustList = () => async (dispatch) => {
  const result = await appApis.getCustList()
  if (result.code === 0) {
    dispatch(setCustList(result.data.custList))
    return true
  } else return false
}

// 사용자 정보 상세
export const getUser = (param) => async (dispatch) => {
  const result = await appApis.getUser(param)
  if (result.code === 0) {
    dispatch(setUser(result.data.user))
    dispatch(setUserType(result.data.type))
    return true
  } else return false
}
// 스캔 등록
export const postScan = (param) => async (dispatch) => {
  const result = await appApis.postScan(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 스캔 삭제
export const deleteScan = (param) => async (dispatch) => {
  const result = await appApis.deleteScan(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 대낭 스캔 리스트
export const getScanLargePouchTracks = (param) => async (dispatch) => {
  const result = await appApis.getScanLargePouchTracks(param)
  if (result.code === 0) {
    dispatch(setScanLargePouchTracks(result.data.tracks))
    return true
  } else return false
}
// 행낭 스캔 리스트
export const getScanPouchTracks = (param) => async (dispatch) => {
  const result = await appApis.getScanPouchTracks(param)
  if (result.code === 0) {
    dispatch(setScanPouchTracks(result.data.tracks))
    return true
  } else return false
}
// 소화물 스캔 리스트
export const getScanFreightTracks = (param) => async (dispatch) => {
  const result = await appApis.getScanFreightTracks(param)
  if (result.code === 0) {
    dispatch(setScanFreightTracks(result.data.tracks))
    return true
  } else return false
}
// 기사 담당 지점리스트
export const getDriverPoints = (param) => async (dispatch) => {
  const result = await appApis.getDriverPoints(param)
  if (result.code === 0) {
    dispatch(setDriverPoints(result))
    return true
  } else return false
}
// 소화물 추적 리스트
export const getFreightTracks = (param) => async (dispatch) => {
  const result = await appApis.getFreightTracks(param)
  if (result.code === 0) {
    dispatch(setFreightTracks(result.data.tracks))
    dispatch(setFreightTracksTotal(result.data.total))
    return true
  } else return false
}
// 행낭 추적 리스트
export const getPouchTracks = (param) => async (dispatch) => {
  const result = await appApis.getPouchTracks(param)
  if (result.code === 0) {
    dispatch(setPouchTracks(result.data.tracks))
    dispatch(setPouchTracksTotal(result.data.total))
    return true
  } else return false
}
// 픽업(한통) 리스트
export const getPickupPouchs = (param) => async (dispatch) => {
  const result = await appApis.getPickupPouchs(param)
  if (result.code === 0) {
    dispatch(setPickupPouchs(result.data.pouchs))
    return true
  } else return false
}
// 배송 완료
export const postDeliveryComplete = (param) => async (dispatch) => {
  const result = await appApis.postDeliveryComplete(param)
  if (result.data.code === 0) {
    return true
  } else return result
}
// 배송 완료 이미지
export const patchDeliveryCompleteImage = (param) => async (dispatch) => {
  const result = await appApis.patchDeliveryCompleteImage(param)
  if (result.code === 0) {
    return true
  } else return false
}
