import api from "../utils/api"
import cookie from "react-cookies"

// 로그인
const signIn = async (data) => {
  const result = await api.post(`/signin`, {
    body: data,
  })
  return result.data
}

export default {
  signIn,
}
