const type = "prod"

const url = {
  prod: {
    // apiURL: "https://api.tramslogistics.tk/app",

    apiURL: "https://app-back.행낭의민족.com",
    
  },
  dev: {
    // apiURL: "https://api.tramslogistics.tk/app",

    apiURL: "http://localhost:3003",
    
  },
}
module.exports = {
  API_URL: url[type].apiURL,
}
