import cookie from "react-cookies"
import signApis from "../apis/signApis"
import appApis from "../apis/appApis"
import { setUser, setUserType } from "./appAction"
import { callNative } from "../utils/util"

// action api function
// 로그인
export const signIn = (param) => async (dispatch) => {
  const result = await signApis.signIn(param)
  if (result.code === 0) {
    cookie.save("app_token", result.data.accessToken, { path: "/" })
    localStorage.setItem("auto_login", result.data.accessToken)
    callNative("ApiToken", { ApiToken: result.data.accessToken })
    const userResult = await appApis.getUser(result.data.accessToken)
    if (userResult.code === 0) {
      dispatch(setUser(userResult.data.user))
      dispatch(setUserType(userResult.data.type))
      return true
    } else return false
  } else return false
}
