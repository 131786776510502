import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  SET_USER,
  SET_USER_TYPE,
  SET_SCAN_LARGE_POUCH_TRACKS,
  SET_SCAN_POUCH_TRACKS,
  SET_SCAN_FREIGHT_TRACKS,
  SET_DRIVER_POINTS,
  SET_FREIGHT_TRACKS,
  SET_FREIGHT_TRACKS_TOTAL,
  SET_POUCH_TRACKS,
  SET_POUCH_TRACKS_TOTAL,
  SET_PICKUP_POUCHS,
  SET_PICKUP_POUCHS_TOTAL,

  SET_CUST_LIST,

} = actions.AppAction

// default state value
const initialState = {
  user: {},
  userType: 0,
  scanLargePouchTracks: [],
  scanPouchTracks: [],
  scanFreightTracks: [],
  driverPoints: [],
  freightTracks: [],
  freightTracksTotal: 0,
  pouchTracks: [],
  pouchTracksTotal: 0,
  pickupPouchs: [],
  pickupPouchsTotal: 0,
  
  custList: [],

}

// handle action
export default handleActions(
  {
    [SET_CUST_LIST]: (state, action) => ({
      ...state,
      custList: action.payload,
    }),

    [SET_USER]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    [SET_USER_TYPE]: (state, action) => ({
      ...state,
      userType: action.payload,
    }),
    [SET_SCAN_LARGE_POUCH_TRACKS]: (state, action) => ({
      ...state,
      scanLargePouchTracks: action.payload,
    }),
    [SET_SCAN_POUCH_TRACKS]: (state, action) => ({
      ...state,
      scanPouchTracks: action.payload,
    }),
    [SET_SCAN_FREIGHT_TRACKS]: (state, action) => ({
      ...state,
      scanFreightTracks: action.payload,
    }),
    [SET_DRIVER_POINTS]: (state, action) => ({
      ...state,
      driverPoints: action.payload,
    }),
    [SET_FREIGHT_TRACKS]: (state, action) => ({
      ...state,
      freightTracks: action.payload,
    }),
    [SET_FREIGHT_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      freightTracksTotal: action.payload,
    }),
    [SET_POUCH_TRACKS]: (state, action) => ({
      ...state,
      pouchTracks: action.payload,
    }),
    [SET_POUCH_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      pouchTracksTotal: action.payload,
    }),
    [SET_PICKUP_POUCHS]: (state, action) => ({
      ...state,
      pickupPouchs: action.payload,
    }),
    [SET_PICKUP_POUCHS_TOTAL]: (state, action) => ({
      ...state,
      pickupPouchsTotal: action.payload,
    }),
  },
  initialState
)
