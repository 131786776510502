import api from "../utils/api"
import cookie from "react-cookies"

// 사용자 정보 상세
const getUser = async (data) => {
  const token = data || (await cookie.load("app_token", { path: "/" }))
  const result = await api.get(`/user`, { token })
  return result.data
}
// 스캔 등록
const postScan = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.post(`/scan`, { token, body: data })
  return result.data
}
// 스캔 삭제
const deleteScan = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.delete(`/scan`, { token, body: data })
  return result.data
}
// 대낭 스캔 리스트
const getScanLargePouchTracks = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.get(`/scan/large/pouch/tracks?status=${data.status}`, { token })
  return result.data
}
// 행낭 스캔 리스트
const getScanPouchTracks = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  let query = `?`
  if (data.status) {
    query += `status=${data.status}`
  }
  if (data.largeTrackSeq) {
    query += `&largeTrackSeq=${data.largeTrackSeq}`
  }
  const result = await api.get(`/scan/pouch/tracks${query}`, { token })
  return result.data
}
// 소화물 스캔 리스트
const getScanFreightTracks = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.get(`/scan/freight/tracks?status=${data.status}`, { token })
  return result.data
}
// 기사 담당 지점리스트
const getDriverPoints = async () => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.get(`/deliver/points`, { token })
  return result.data
}
// 소화물 추적 리스트
const getFreightTracks = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  let query = `?`
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/freight/tracks${query}`, { token })
  return result.data
}
// 행낭 추적 리스트
const getPouchTracks = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  let query = `?`
  // if (data.page !== undefined) {
  //   query += `page=${data.page}`
  // }
  // if (data.count) {
  //   query += `&count=${data.count}`
  // }
  // if (data.startDate) {
  //   query += `&startDate=${data.startDate}`
  // }
  // if (data.endDate) {
  //   query += `&endDate=${data.endDate}`
  // }
  // if (data.searchType) {
  //   query += `&searchType=${data.searchType}`
  // }
  // if (data.search) {
  //   query += `&search=${data.search}`
  // }

  query += `&custSeq=${data.custSeq}`
  
  // const result = await api.get(`/pouch/tracks${query}`, { token })
  const result = await api.get(`/pouch/track/list${query}`, { token })
  
  return result.data
}
// 픽업(한통) 리스트
const getPickupPouchs = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.get(`/pickup/pouchs?date=${data.date}&pointSeq=${data.pointSeq}`, { token })
  return result.data
}
// 배송 완료
const postDeliveryComplete = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.post(`/delivery/complete`, { token, body: data })
  return result
}
// 배송 완료 이미지
const patchDeliveryCompleteImage = async (data) => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.get(`/delivery/complete/image`, { token, body: data })
  return result.data
}

const getCustList = async () => {
  const token = await cookie.load("app_token", { path: "/" })
  const result = await api.get(`/cust/list`, { token, body: {} })
  return result.data
}

export default {
  getUser,
  postScan,
  deleteScan,
  getScanLargePouchTracks,
  getScanPouchTracks,
  getScanFreightTracks,
  getDriverPoints,
  getFreightTracks,
  getPouchTracks,
  getPickupPouchs,
  postDeliveryComplete,
  patchDeliveryCompleteImage,
  getCustList,
}
