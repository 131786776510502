// import { configureStore } from "../store"

// 전화번호에 - 붙이는 함수
export function phoneNumFormat(num, type) {
  if (num === null) return ""

  let formatNum = ""
  if (num.length === 11) {
    if (type === 0) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-****-$3")
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
    }
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, "$1-$2")
  } else {
    if (num.indexOf("02") === 0) {
      if (type === 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-****-$3")
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3")
      }
    } else {
      if (type === 0) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-***-$3")
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
      }
    }
  }

  return formatNum
}

export const GetStatus = (num) => {
  const arr = ["지사 발신", "옥천 상차", "한통 발신", "한통 수신", "배송완료", "한통 픽업", "한통 분류", "옥천 상차", "옥천 하차", "지사 픽업"]
  return arr[num - 1]
}

// 크기 변환
export function WidthToDP(px) {
  // const screenWidth = configureStore.getState().ConfigReducer.screenWidth
  // console.log(screenWidth)
  // 제플린 화면 넓이가 750 일 때
  return (360 / 750) * px
}

// 네이티브 연동
export function callNative(Type, Data) {
  window.callNative(JSON.stringify({ Type, Data }))
}

// 안드로이드 백 핸들러
export function androidBackHandler(func) {
  window.NativeDataRecive = (_data) => {
    switch (_data.Type) {
      case "Back":
        func()
        break
      default:
        break
    }
  }
}
